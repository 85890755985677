import { chiSonoTextSmall, chiSonoText, passioniTextSmall, passioniText, cosaFaccioTextSmall, cosaFaccioText, cosaFaccioText2 } from '../Info'
import { ChiSono } from '../Pages/ChiSono'
import { CosaFaccio } from '../Pages/CosaFaccio'
import { Home } from '../Pages/Home'
import { Passioni } from '../Pages/Passioni'

export const baseColor = '#993301'
export const baseGray = '#AAAAAA'
export const baseShadow = '0 0 8px rgba(0, 0, 0, 0.3)'
export const smallWindow = 768
export const delay = 0.5

// Pages
export const routes = {
    home: {
        title: 'home',
        path: '/',
        textSmall: ``,
        text: ``,
        element: <Home />,
    },
    chiSono: {
        title: 'chi sono',
        path: '/chisono',
        textSmall: chiSonoTextSmall,
        text: chiSonoText,
        element: <ChiSono />,
    },
    cosaFaccio: {
        title: 'cosa faccio',
        path: '/cosafaccio',
        textSmall: cosaFaccioTextSmall,
        text: cosaFaccioText,
        text2: cosaFaccioText2,
        element: <CosaFaccio />,
    },
    passioni: {
        title: 'passioni',
        path: '/passioni',
        textSmall: passioniTextSmall,
        text: passioniText,
        element: <Passioni />,
    },
}

// Motions
export const motionSlide = (delay: number) => ({
    initial: { x: '-5%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { duration: 1, ease: `easeInOut`, delay: delay },
})
export const motionSlideUp = (delay: number) => ({
    initial: { y: '+5%', opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.5, ease: `easeInOut`, delay: delay },
})
export const motionFadeIn = (delay: number) => ({
    initial: { x: 0, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { duration: 1.5, ease: `easeInOut`, delay: delay },
})