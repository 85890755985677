import { FaPhoneAlt, FaLinkedin, FaFacebook, FaInstagram, IoMdMail } from './Utils/Imports'

export const contactInfo = [
    { href: `https://www.google.com/maps/place/Via+Alba+Maldini,+8,+40069+Zola+Predosa+BO/@44.4850499,11.2114991,17z/data=!3m1!4b1!4m6!3m5!1s0x477fd700a89ca9b5:0xfff9dceb493a52ef!8m2!3d44.48505!4d11.21637!16s%2Fg%2F11cs7skrbc?entry=ttu&g_ep=EgoyMDI0MTEwNS4wIKXMDSoASAFQAw%3D%3D`, label: `Via Alba Maldini 8 Zola Predosa BO` },
    { href: `tel:+393336536062`, label: `+39 333 6536062` },
    { href: `mailto:damianotedeschi@yahoo.it`, label: `damianotedeschi@yahoo.it` },
    { href: `mailto:damiano.tedeschi@archiworldpec.it`, label: `PEC damiano.tedeschi@archiworldpec.it` },
    { label: `P.IVA 02854311202` },
    { label: `CF TDSDMN68R01A944Y` },
]

export const iconInfo = [
    { href: `tel:+393336536062`, icon: <FaPhoneAlt size={16} /> },
    { href: `mailto:damianotedeschi@yahoo.it`, icon: <IoMdMail size={23} /> },
    { href: `https://it.linkedin.com/in/damiano-tedeschi-6452a1163`, icon: <FaLinkedin size={20} /> },
    { href: `https://www.facebook.com/damiano.tedeschi.5855/?_rdr`, icon: <FaFacebook size={20} /> },
    { href: `https://www.instagram.com/damiano_tedeschi`, icon: <FaInstagram size={20} /> },
]

export const chiSonoTextSmall = [
    `Sono Damiano Tedeschi, architetto con ${new Date().getFullYear() - 1993} anni di esperienza in progettazione, ristrutturazione e coordinamento di lavori edili.
    Specializzato in sicurezza, prevenzione incendi e consulenze per il Tribunale, ho lavorato su progetti residenziali,
    commerciali e scolastici, creando soluzioni innovative e integrate.`,
]

export const chiSonoText = [
    `<strong>Studi e Formazione</strong>
    Sono Damiano Tedeschi, nato a Bologna il 1° ottobre 1968. Mi sono laureato in Architettura presso l’Università degli Studi di Firenze e, 
    dopo aver conseguito l’abilitazione professionale, mi sono iscritto all'Ordine degli Architetti della Provincia di Bologna nel gennaio 1994, 
    che nel 1995 mi ha conferito il Premio Edoardo Collamarini per l’Anno Accademico 1992/1993, in riconoscimento dell’eccellenza negli studi.
    Nel 1999 ho ottenuto l’abilitazione come Coordinatore per la sicurezza in fase di progettazione e di esecuzione (D.lgs. 494/96), 
    e la specializzazione in prevenzione incendi (Legge 818/84).`,

    `<strong>Carriera Professionale</strong>
    La mia carriera si è sviluppata in studi di Architettura e Ingegneria, dove ho acquisito esperienza nella redazione di progetti esecutivi
    per diverse tipologie di edifici: residenziali, commerciali, scolastici, produttivi e terziari, oltre a gestire la direzione dei lavori. 
    Mi sono specializzato nella ristrutturazione edilizia, nel restauro e nella progettazione di interni,
    e mi occupo anche di progettazione per allestimenti temporanei, come quelli fieristici e sportivi motoristici.
    Lavorando a stretto contatto con team di ingegneri e professionisti, ho sviluppato competenze avanzate nella progettazione integrata e coordinata. 
    Mi occupo inoltre di valutazioni economiche per investimenti immobiliari e di analisi di marginalità, oltre a dirigere lavori edili e allestimenti temporanei. 
    Infine, offro consulenze tecniche per il Tribunale, fornendo perizie e analisi specialistiche, mettendo la mia esperienza al servizio della giustizia.`
]

export const passioniTextSmall = [
    `<strong>Architettura</strong> - L’architettura è una passione che mi spinge a progettare spazi equilibrati tra bellezza, funzionalità e innovazione.`,
    `<strong>Animali</strong> - Gli animali mi insegnano la semplicità, il rispetto per la vita e l’importanza di vivere il presente con calma ed empatia.`,
    `<strong>Natura</strong> - La natura ispira meraviglia e ci invita a rallentare, trovando forza nella sua semplicità.`,
    `<strong>Sport</strong> -  Lo sport mi mantiene in equilibrio, soprattutto all’aperto e negli sport di squadra, insegnandomi collaborazione e fiducia.`,
    `<strong>Arte</strong> - Disegnare è il mio modo di esprimere emozioni e idee, trasformandole in immagini.`,
]

export const passioniText = {
    intro: [
        `Le passioni raccontano chi siamo, influenzano il nostro modo di vedere il mondo e di affrontare la vita. 
        In questa pagina condivido ciò che mi ispira e mi arricchisce ogni giorno, unendo mente e cuore, 
        lavoro e tempo libero. Sono i dettagli, i momenti e le esperienze che rendono autentica la mia visione 
        e alimentano la creatività nel mio percorso personale e professionale.`,
    ],
    architettura: [
        `L’architettura è per me molto più di una professione: è una passione che mi spinge a osservare il mondo 
        con occhi curiosi e creativi. È l’arte di dare forma agli spazi che viviamo, trovando un equilibrio tra bellezza 
        e funzionalità, tra innovazione e rispetto per il contesto. Progettare significa raccontare storie attraverso i 
        materiali, la luce e le proporzioni, creando luoghi che non siano solo utili, ma che sappiano anche emozionare e 
        ispirare chi li vive.`,
    ],
    animali: [
        `Gli animali rappresentano per me una fonte inesauribile di gioia e connessione autentica. 
        Con la loro semplicità e purezza, mi ricordano l’importanza di vivere il presente e di rispettare ogni forma di vita. 
        La loro presenza è un’ispirazione costante, capace di trasmettere calma, empatia e quella straordinaria capacità di 
        comunicare senza bisogno di parole.`,
    ],
    natura: [
        `La natura è una fonte infinita di bellezza e mistero, capace di ispirare meraviglia in ogni suo dettaglio. 
        Ogni paesaggio, ogni pianta, ogni animale raccontano storie di equilibrio e resilienza. La sua capacità di 
        rinnovarsi continuamente è un costante promemoria della grandezza del mondo che ci circonda, un invito a rallentare, 
        osservare e trarre forza dalla sua purezza e semplicità.`,
    ],
    sport: [
        `Lo sport è sempre stata una delle mie più grandi passioni, un’attività che mi ha insegnato a raggiungere 
        un equilibrio sia fisico che mentale. Mi entusiasma praticarlo all’aria aperta, dove la connessione con 
        l’ambiente arricchisce ogni momento. In particolare, gli sport di squadra mi hanno insegnato l’importanza 
        della collaborazione e della fiducia reciproca, valori che porto con me anche nel mio lavoro e nella vita quotidiana.`,
    ],
    arte: [
        `L’arte ha sempre avuto un ruolo fondamentale nella mia vita. Fin da piccolo ho amato disegnare,  
        trovando nella creatività un modo per esprimermi liberamente e dare forma alle mie idee.  
        Questa passione mi ha portato a frequentare il liceo artistico, un’esperienza che ha arricchito la mia visione estetica  
        e affinato la mia sensibilità verso forme, colori e composizioni. L’arte continua a essere per me una fonte  
        inesauribile di ispirazione, capace di trasformare emozioni e pensieri in immagini tangibili.`,
    ],
}

export const cosaFaccioTextSmall = [
    `<strong>Progettazione</strong> - Cura delle esigenze del cliente in tutte le sue sfaccettature.`,
    `<strong>Organizzazione</strong> - Gestione di tutti gli aspetti tecnici e realizzativi.`,
    `<strong>Realizzazione</strong> - Concretizzazione delle aspettative del cliente.`,
    `<strong>Direzione Lavori</strong> - Supervisione delle esigenze del cliente in ogni fase del processo.`,
    `<strong>Studi di Fattibilità</strong> - Esperienza a servizio del cliente.`,
    `<strong>Analisi Commerciali ed Economiche</strong> - Professionalità a servizio del cliente.`,
    `<strong>Perizie e Consulenze</strong> - Esperienza e professionalità a disposizione del cliente.`,
]

export const cosaFaccioText = [
    `Ho le competenze per occuparmi di tutto l’iter progettuale e realizzativo della commessa:
    dallo studio di fattibilità alla progettazione architettonica, dalla progettazione esecutiva alla direzione lavori,
    fino alla rappresentazione grafica in 3D dell’intervento e alla sua visualizzazione per fini promozionali.`,

    `Attraverso qualificate partnership, offro la progettazione e la realizzazione di tutta l’impiantistica,
    garantendo la consegna 'chiavi in mano' di qualsiasi richiesta, sia in ambito edile (civile, industriale, ristrutturazioni, recuperi, ecc.),
    sia nell’allestimento e gestione di eventi temporanei per spettacoli, manifestazioni sportive o fieristiche.`,

    `In questo ambito, l’esperienza che ho maturato nel tempo mi permette di valutare le criticità nella gestione di uno spazio,
    individuando soluzioni efficaci per ottimizzare la gestione da parte dello staff organizzativo, mantenendo al contempo uno
    spazio piacevole e funzionale per i visitatori.`,
]

export const cosaFaccioText2 = [
    `<strong>Coordinatore per la sicurezza</strong> – Abilitazione per le funzioni di coordinatore per la progettazione e l’esecuzione in materia di sicurezza ai sensi del D.Lgs. 494/96 (attuazione della Direttiva CEE 92/57), conseguita il 28 giugno 1999 presso l’Ordine degli Architetti di Bologna.`,
    `<strong>Prevenzione incendi</strong> – Abilitazione di specializzazione in prevenzione incendi ai sensi della Legge 818/84, conseguita nel dicembre 1999 (n. BO2506A165).`,
    `<strong>Certificazione Energetica</strong> – Iscritto con il n. 05792 nell’Elenco Regionale dei soggetti accreditati alla Certificazione Energetica degli edifici della Regione Emilia-Romagna dal febbraio 2012`,
]